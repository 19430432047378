import { Row, Col, Card, Typography } from "antd";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "../constants";
import { useAuth } from "../hooks/useAuth";

const links = [
  {
    label: "New order",
    key: "orders-new",
    url: ROUTES.ordersNew,
    visibility: ["admin", "staff", "manager"],
  },
  {
    label: "Edit price",
    key: "bulk-edit",
    url: ROUTES.productsBulkEdit,
    visibility: ["admin", "staff", "manager"],
  },
  {
    label: "Add customer",
    key: "customers-new",
    url: ROUTES.customersNew,
    visibility: ["admin", "staff", "manager", "finance"],
  },
  {
    label: "Customers",
    key: "sales-portal",
    url: "/sales-portal",
    visibility: ["sales"],
  },
];

export const HomePage = () => {
  const [quickLinks, setQuickLinks] = useState([]);
  const { user } = useAuth();

  useEffect(() => {
    if (user) {
      setQuickLinks(links.filter((link) => link.visibility.includes(user.role)));
    }
  }, [user]);

  return (
    <Row gutter={16}>
      {quickLinks.map((qlink) => {
        return (
          <Col key={qlink.key} span={6}>
            <Link to={qlink.url}>
              <Card>
                <Typography.Title level={1}>{qlink.label}</Typography.Title>
              </Card>
            </Link>
          </Col>
        );
      })}
    </Row>
  );
};
