import { Routes, Route } from "react-router-dom";
import { DefaultLayout, DefaultUnauthenticatedLayout } from "../layouts";

import { HomePage } from "../pages/Home";
import { Login } from "../pages/Login";
import { Statements } from "../pages/Statements";
import { Invoices } from "../pages/Invoices";
import { LogisticHome } from "../pages/LogisticHome";
import { Analytics } from "../pages/Analytics";
import { SalesPortal } from "../pages/SalesPortal";
import { BuyersPortal } from "../pages/BuyersPortal";
import { LineItems } from "../pages/LineItems";

import { Payments } from "../pages/Payments";
import { NewPayment } from "../pages/NewPayment";
import { PaymentDetail } from "../pages/PaymentDetail";
import { EditPayment } from "../pages/EditPayment";

import { ProductDetail } from "../pages/ProductDetail";
import { Products } from "../pages/Products";
import { NewProduct } from "../pages/NewProduct";
import { EditProduct } from "../pages/EditProduct";
import { ProductsBulkEdit } from "../pages/ProductsBulkEdit";

import { OrdersPage } from "../pages/Orders";
import { NewOrder } from "../pages/NewOrder";
import { EditOrder } from "../pages/EditOrder";
import { OrderDetail } from "../pages/OrderDetail";

import { Franchises } from "../pages/Franchises";

import { ProductAllocation } from "../pages/ProductAllocation";
import { AllocationHome } from "../pages/Allocation";

import { NewCreditNote } from "../pages/NewCreditNote";
import { CreditNoteDetail } from "../pages/CreditNoteDetail";
import { EditCreditNote } from "../pages/EditCreditNote";

import { CustomerDetail } from "../pages/CustomerDetail";
import { Customers } from "../pages/Customers";
import { NewCustomer } from "../pages/NewCustomer";
import { EditCustomer } from "../pages/EditCustomer";
import { CustomerOrderHistorySearch } from "../pages/CustomerOrderHistorySearch";

import { ExpensesHome } from "../pages/Expenses";
import { NewExpenses } from "../pages/NewExpenses";
import { NewOrderV2 } from "../pages/orders/v2/New";
import { EditOrderV2 } from "../pages/orders/v2/Edit";
import { BulkEdit } from "../pages/products/v2/BulkEdit";
import { LogisticSetup } from "../pages/LogisticSetup";
import { QuotationsPage } from "../pages/Quotations";
import { QuotationDetail } from "../pages/QuotationDetails";
import { EditQuotation } from "../pages/EditQuotation";
import { NewQuotation } from "../pages/NewQuotation";
import { PricingAnalysis } from "../pages/FranchisePricingAnalysis";
import { DeliveryRunOrdersList } from "../pages/deliveryRuns/DeliveryRun";
import { Playground } from "../pages/Playground";
import { LogisticByDate } from "@/pages/LogisticByDate";
import { StockTake } from "@/pages/StockTake";
import { ChangeCategories } from "@/pages/ChangeCategories";
import { EverfreshOrdersPage } from "@/pages/Everfresh";
import { Unauthorised } from "@/pages/Unauthorised";

const useDefaultLayout = (page) => <DefaultLayout>{page}</DefaultLayout>;

const useDefaultUnauthenticatedLayout = (page) => (
  <DefaultUnauthenticatedLayout>{page}</DefaultUnauthenticatedLayout>
);

export const RoutesTree = () => (
  <Routes>
    <Route path="/" element={useDefaultLayout(<HomePage />)} />
    <Route path="login" element={<Login />} />

    <Route path="payments">
      <Route path="new" element={useDefaultLayout(<NewPayment />)} />
      <Route path=":id">
        <Route index element={useDefaultLayout(<PaymentDetail />)} />
        <Route path="edit" element={useDefaultLayout(<EditPayment />)} />
      </Route>
      <Route index element={useDefaultLayout(<Payments />)} />
    </Route>

    <Route path="products">
      <Route path="new" element={useDefaultLayout(<NewProduct />)} />
      <Route path=":id">
        <Route index element={useDefaultLayout(<ProductDetail />)} />
        <Route path="edit" element={useDefaultLayout(<EditProduct />)} />
      </Route>
      <Route path="v2">
        <Route path="bulk-edit" element={useDefaultLayout(<BulkEdit />)} />
        <Route path="stock-take" element={useDefaultLayout(<StockTake />)} />
        <Route path="change-categories" element={useDefaultLayout(<ChangeCategories />)} />
      </Route>
      <Route path="bulk-edit" element={useDefaultLayout(<ProductsBulkEdit />)} />
      <Route index element={useDefaultLayout(<Products />)} />
    </Route>

    <Route path="orders">
      <Route path="new" element={useDefaultLayout(<NewOrder />)} />
      <Route path=":id">
        <Route index element={useDefaultLayout(<OrderDetail />)} />
        <Route path="edit" element={useDefaultLayout(<EditOrder />)} />
      </Route>
      <Route index element={useDefaultLayout(<OrdersPage />)} />
      <Route path="v2">
        <Route path="new" element={useDefaultLayout(<NewOrderV2 />)} />
        <Route path=":id">
          <Route path="edit" element={useDefaultLayout(<EditOrderV2 />)} />
        </Route>
      </Route>
      <Route index element={useDefaultLayout(<OrdersPage />)} />
    </Route>

    <Route path="allocation">
      <Route path=":id" element={useDefaultLayout(<ProductAllocation />)} />
      <Route index element={useDefaultLayout(<AllocationHome />)} />
    </Route>

    <Route path="quotations">
      <Route index element={useDefaultLayout(<QuotationsPage />)} />
      <Route path="new" element={useDefaultLayout(<NewQuotation />)} />
      <Route path=":id">
        <Route index element={useDefaultLayout(<QuotationDetail />)} />
        <Route path="edit" element={useDefaultLayout(<EditQuotation />)} />
      </Route>
    </Route>

    <Route path="customers">
      <Route path="new" element={useDefaultLayout(<NewCustomer />)} />
      <Route path=":id">
        <Route index element={useDefaultUnauthenticatedLayout(<CustomerDetail />)} />
        <Route path="edit" element={useDefaultLayout(<EditCustomer />)} />
        <Route path="history" element={useDefaultLayout(<CustomerOrderHistorySearch />)} />
      </Route>
      <Route index element={useDefaultLayout(<Customers />)} />
    </Route>

    <Route path="expenses">
      <Route path="new" element={useDefaultLayout(<NewExpenses />)} />
      <Route index element={useDefaultLayout(<ExpensesHome />)} />
    </Route>
    <Route path="franchises" element={useDefaultLayout(<Franchises />)}>
      <Route index element={useDefaultLayout(<Franchises />)} />
    </Route>

    <Route path="credit-notes">
      <Route path="new" element={useDefaultLayout(<NewCreditNote />)} />
      <Route path=":id">
        <Route index element={useDefaultLayout(<CreditNoteDetail />)} />
        <Route path="edit" element={useDefaultLayout(<EditCreditNote />)} />
      </Route>
    </Route>

    <Route path="lineItems" element={useDefaultLayout(<LineItems />)} />
    <Route path="logistic">
      <Route index element={useDefaultLayout(<LogisticHome />)} />
      <Route path=":id">
        <Route index element={useDefaultLayout(<LogisticByDate />)} />
      </Route>
    </Route>

    <Route path="logistic-setup" element={useDefaultLayout(<LogisticSetup />)} />
    <Route path="statements" element={useDefaultLayout(<Statements />)} />
    <Route path="invoices" element={useDefaultLayout(<Invoices />)} />
    <Route path="analytics" element={useDefaultLayout(<Analytics />)} />
    <Route path="sales-portal" element={useDefaultLayout(<SalesPortal />)} />
    <Route path="buyers-portal" element={useDefaultLayout(<BuyersPortal />)} />
    <Route path="pricing-analysis" element={useDefaultLayout(<PricingAnalysis />)} />
    <Route path="ever-fresh-orders" element={useDefaultLayout(<EverfreshOrdersPage />)} />

    <Route path="unauthorised" element={<Unauthorised />} />
    <Route path="/delivery-runs/:day/trucks/:truck" element={<DeliveryRunOrdersList />} />
    <Route path="playground" element={<Playground />} />
  </Routes>
);
