import { Form, Col, Row, Card, Input, Select, InputNumber, Typography, AutoComplete } from "antd";
import { useNavigate } from "react-router-dom";
import { useSalespeople } from "../../hooks/useSalespeople";
import { useFranchises } from "../../hooks/useFranchises";
import { useAllCustomers } from "../../hooks/useAllCustomers";

const { Title } = Typography;

const VendorOptions = [
  {
    label: "Glory Fresh",
    value: "GLORY_FRESH",
  },
  {
    label: "Newnorth",
    value: "NEWNORTH",
  },
];

export const CustomerForm = ({ form }) => {
  const { salespeople } = useSalespeople();
  const { franchises } = useFranchises();
  const { customers } = useAllCustomers();
  const navigate = useNavigate();

  const handleSelect = (_, option) => {
    form.setFields([
      { name: "franchiseName", value: option.value },
      { name: "franchiseId", value: option.id },
      { name: "actionType", value: option.actionType },
    ]);
  };

  const handleCustomerSelect = (_, option) => {
    form.setFieldsValue({ name: "" });
    navigate(`/customers/${option.id}/edit`);
  };

  return (
    <Form form={form} layout="vertical">
      <Row gutter={[32, 16]}>
        <Col span={16}>
          <Card title={<Title level={3}>Basic info</Title>}>
            <Form.Item
              name="name"
              label="Name"
              rules={[{ required: true, message: "please input a customer name" }]}
            >
              <AutoComplete
                onSelect={handleCustomerSelect}
                filterOption={(inputValue, option) => {
                  return !option.value
                    ? false
                    : option?.value.toUpperCase().indexOf(inputValue?.toUpperCase()) !== -1;
                }}
                placeholder="Customer's name"
                options={customers}
              />
            </Form.Item>
            <Form.Item name="deliveryAddress" label="Delivery Address">
              <Input placeholder="Delivery address" />
            </Form.Item>
            <Form.Item name="notes" label="Notes">
              <Input placeholder="Delivery notes" />
            </Form.Item>
            <Form.Item name="phone" label="Phone">
              <Input placeholder="Phone number" />
            </Form.Item>
            <Form.Item name="email" label="Email">
              <Input placeholder="Email address" />
            </Form.Item>
            <Form.Item name="LEGACY_customerCode" label="Customer code of OLD system">
              <Input placeholder="The customer code for the OLD system" />
            </Form.Item>
          </Card>
        </Col>
        <Col span={8}>
          <Card title={<Title level={3}>Vendor</Title>} style={{ marginBottom: "20px" }}>
            <Form.Item name="invoiceTemplate">
              <Select options={VendorOptions} defaultValue="GLORY_FRESH" />
            </Form.Item>
          </Card>
          <Card title={<Title level={3}>Salesperson</Title>} style={{ marginBottom: "20px" }}>
            <Form.Item name="salesperson">
              <Select options={salespeople} />
            </Form.Item>
          </Card>
          <Card title={<Title level={3}>Pricing</Title>} style={{ marginBottom: "20px" }}>
            <Form.Item name="fixedMargin" label="Fixed margin (e.g. 0.3 means 30%)">
              <InputNumber
                stringMode={true}
                precision={4}
                placeholder="Fixed margin"
                style={{
                  width: "100%",
                }}
              />
            </Form.Item>
          </Card>
          <Card title={<Title level={3}>Franchise</Title>} style={{ marginBottom: "20px" }}>
            <Form.Item
              name="franchiseName"
              label="Connect the customer to a existing franchise or create a new franchise"
            >
              <AutoComplete
                onSelect={handleSelect}
                filterOption={(inputValue, option) => {
                  return !option.value
                    ? false
                    : option?.value.toUpperCase().indexOf(inputValue?.toUpperCase()) !== -1;
                }}
                placeholder="Type to search..."
                options={franchises}
              />
            </Form.Item>
            <Form.Item name="franchiseId" hidden>
              <Input />
            </Form.Item>
            <Form.Item name="actionType" hidden>
              <Input />
            </Form.Item>
          </Card>
        </Col>
      </Row>
    </Form>
  );
};
