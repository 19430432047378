import { useState, useEffect } from "react";
import { Table, Space, PageHeader, Button, Typography, Select, Input } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { ROUTES } from "../constants";
import { useQuery } from "@apollo/client";
import { Link, useSearchParams } from "react-router-dom";
import moment from "moment";
import { VendorsMap } from "../constants";
import { ToolbarWrapper } from "../components/atoms/ToolbarWrapper";
import { SortedCustomersQuery } from "../graphql/customers.graphql";
import { PageContentContainer } from "../components/atoms/PageContentContainer";
import { PageContainer } from "../components/atoms/PageContainer";
import { CUSTOMER_SORT_OPTIONS } from "../constants";
import { SyncOutlined } from "@ant-design/icons";
import { SalespeopleQuery } from "../graphql/users.graphql";
import axios from "axios";
import { debounce, set } from "lodash";
import { formatMoney } from "@/utils";

const columns = [
  {
    title: "Customer",
    dataIndex: "name",
    key: "name",
    render: (customerName, record) => {
      return (
        <>
          <Typography.Title level={5} style={{ marginBottom: 0 }}>
            <Link to={`/customers/${record.id}`} preventScrollReset={true}>
              {customerName}
            </Link>
          </Typography.Title>
          <span>{record.organisation?.name}</span>
        </>
      );
    },
  },
  {
    title: "Address",
    dataIndex: "deliveryAddress",
    key: "deliveryAddress",
  },
  {
    title: "No. of orders",
    dataIndex: "ordersCount",
    key: "ordersCount",
    render: (_, record) => `${record._count.orders} orders`,
  },
  {
    title: "Last order",
    dataIndex: "lastOrder",
    key: "lastOrder",
    render: (_, record) => {
      if (record.orders.length > 0) {
        return moment(record.orders[0].orderDate).fromNow();
      }
    },
  },
  {
    title: "Account balance",
    dataIndex: "accountBalance",
    key: "accountBalance",
    align: "right",
    render: formatMoney,
  },
  {
    title: "Vendor",
    dataIndex: "invoiceTemplate",
    key: "vendor",
    render: (value) => VendorsMap[value].label,
  },
  {
    title: "Salesperson",
    dataIndex: ["salesperson", "name"],
    key: "salesperson.name",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    key: "actions",
    align: "right",
    render: (_, record) => {
      return (
        <Space>
          <Button>
            <Link to={`/customers/${record.id}/edit`}>
              Edit <EditOutlined />
            </Link>
          </Button>
        </Space>
      );
    },
  },
];

const pageSize = 50;

const fetchCustomers = async ({ query, isFranchise, invoiceTemplate, take, skip }) => {
  const response = await axios(`${process.env.REACT_APP_REST_API_ENDPOINT}/customers`, {
    params: {
      query,
      isFranchise,
      take,
      skip,
      invoiceTemplate,
    },
  });

  if (response.status >= 200) {
    return response.data;
  }
};

export const Customers = () => {
  const [customers, setCustomers] = useState([]);
  const [customersCount, setCustomersCount] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentPage, setCurrentPage] = useState(Number(searchParams.get("page") || "1"));
  const [query, setQuery] = useState(searchParams.get("query") || "");
  const [vendor, setVendor] = useState(searchParams.get("vendor") || "");
  const [loading, setLoaidng] = useState(false);

  useEffect(() => {
    const vendor = searchParams.get("vendor") || "";
    const query = searchParams.get("query") || "";
    const currentPage = Number(searchParams.get("page") || "1");

    setCurrentPage(currentPage);
    setVendor(vendor);
    setQuery(query);

    const fetchCustomersData = async () => {
      setLoaidng(true);
      const res = await fetchCustomers({
        query,
        take: pageSize,
        skip: (currentPage - 1) * pageSize,
        invoiceTemplate: vendor,
        isFranchise: false,
      });

      if (res) {
        setCustomers(res.customers);
        setCustomersCount(res.total);
      }

      setLoaidng(false);
    };

    fetchCustomersData();
  }, [searchParams]);

  const handleSearch = (value) => {
    setQuery(value);
    setSearchParams({ query: value, page: currentPage, vendor }, { replace: true });
  };

  const handleVendorFilterChange = (value) => {
    setVendor(value);
    setSearchParams({ query, page: currentPage, vendor: value }, { replace: true });
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    setSearchParams({ query, page, vendor }, { replace: true });
  };

  return (
    <PageContainer>
      <PageHeader
        ghost={true}
        title="Customers"
        subTitle={`Showing ${customers.length} customers of ${customersCount}`}
        extra={[
          <Link key="new-customer" to={ROUTES.customersNew}>
            <Button type="primary">New customer</Button>
          </Link>,
        ]}
      />

      <PageContentContainer>
        <ToolbarWrapper>
          <Space>
            <Input.Search
              placeholder="Search name or code"
              enterButton
              allowClear
              defaultValue={query}
              onSearch={handleSearch}
              style={{ width: 320 }}
            />
            <Select
              defaultValue=""
              style={{ width: 145 }}
              onChange={handleVendorFilterChange}
              value={vendor}
              options={[
                {
                  value: "",
                  label: "All vendors",
                },
                {
                  value: "NEWNORTH",
                  label: "Newnorth",
                },
                {
                  value: "GLORY_FRESH",
                  label: "Glory Fresh",
                },
                {
                  value: "EVER_FRESH",
                  label: "Everfresh Group",
                },
              ]}
            />
          </Space>
          <Space>
            <Select
              disabled
              defaultValue="name=1"
              style={{ width: 145 }}
              options={[
                {
                  value: "name=1",
                  label: "Name A-Z",
                },
                {
                  value: "name=-1",
                  label: "Name Z-A",
                },
                {
                  value: "ordersCount=1",
                  label: "Orders ↑",
                },
                {
                  value: "ordersCount=-1",
                  label: "Orders ↓",
                },
                {
                  value: "lastOrder=1",
                  label: "Last order ↑",
                },
                {
                  value: "lastOrder=-1",
                  label: "Last order ↓",
                },
              ]}
            />
            <Button icon={<SyncOutlined />}>Refresh</Button>
          </Space>
        </ToolbarWrapper>
        <Table
          dataSource={customers}
          columns={columns}
          rowKey="id"
          size="medium"
          loading={loading}
          pagination={{
            current: currentPage,
            total: customersCount,
            pageSize: pageSize,
            showSizeChanger: false,
            onChange: handlePageChange,
          }}
        />
      </PageContentContainer>
    </PageContainer>
  );
};
