import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { notification, Button, Form } from "antd";

import { OrderFormProvider } from "../../../contexts/OrderFormContext";
import { OrdersQuery, CreateOrderMutation } from "../../../graphql/orders.graphql";

import { CustomerSelection } from "../../../components/CustomerSelection";
import { OrderFormV2 } from "../../../components/OrderFormV2";
import UnsavedReminder from "../../../components/atoms/UnsavedReminder";
import { useCallbackPrompt } from "../../../hooks/useCallbackPrompt";
import { CustomerContext } from "../../../contexts/CustomerContext";
import { AffixedPageHeader } from "../../../components/atoms/AffixedPageHeader";
import { sanitizeOrderFormValues, getInitialOrderDate } from "../../../utils";
import { PageContainer } from "../../../components/atoms/PageContainer";

export const NewOrderV2 = () => {
  const navigate = useNavigate();
  const { customer, fetchCustomer, resetCustomer } = useContext(CustomerContext);
  const [form] = Form.useForm();

  // unsavedReminder
  const [showModal, setShowModal] = useState(false);
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(true);
  const [isSaved, setIsSaved] = useState(false);

  const [createOrder] = useMutation(CreateOrderMutation, {
    refetchQueries: [{ query: OrdersQuery }],
  });

  useEffect(() => {
    if (!customer) return;
    const orderDate = getInitialOrderDate();

    form.setFieldsValue({
      basicInfo: {
        orderDate,
        fulfillmentType: "delivery",
        deliveryAddress: customer.deliveryAddress,
        notes: customer.notes,
      },
      lineItems: [undefined],
    });

    return () => {
      console.log("cleaning up in New.js.....");
      resetCustomer();
    };
  }, [customer, form, resetCustomer]);

  useEffect(() => {
    if (isSaved) {
      setShowModal(false);
    } else setShowModal(showPrompt);
  }, [showPrompt, isSaved]);

  const handleSave = async () => {
    const values = form.getFieldsValue();
    const payload = sanitizeOrderFormValues({ values });
    setIsSaved(true);

    const res = await createOrder({
      variables: { order: { ...payload, customer: { connect: { id: customer.id } } } },
    });

    if (res.data) {
      notification["success"]({
        message: `Order for ${res.data.createOrder.customer?.name} successfully created`,
      });

      navigate("/orders");
      confirmNavigation();
    }
  };

  const handleCustomerSelect = (_, option) => {
    fetchCustomer({ variables: { id: option.id } });
  };
  return (
    <>
      <AffixedPageHeader
        title={`New order · ${customer?.name || ""}`}
        extra={[
          <Button type="primary" key="save" onClick={handleSave}>
            Save
          </Button>,
        ]}
      />
      <UnsavedReminder
        showModal={showModal}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation}
      />
      {customer ? (
        <OrderFormProvider order={null} form={form}>
          <PageContainer>
            <OrderFormV2 />
          </PageContainer>
        </OrderFormProvider>
      ) : (
        <CustomerSelection onCustomerSelect={handleCustomerSelect} />
      )}
    </>
  );
};
