import { Page, View, Document, Font } from "@react-pdf/renderer";
import { HorizontalLine, Typography, PageNumber, Row, Stack } from "./utilities";
import { DATE_FORMAT } from "../../constants/appConstants";

import moment from "moment-timezone";

Font.register({
  family: "Microsoft YaHei",
  src: "/fonts/chinese.msyh.ttf",
});

const isPrepacked = (unitName) => {
  const prePackedValues = ["BAG", "BIGBAG", "BOX", "TRAY"];
  return !!prePackedValues.find((p) => unitName.includes(p));
};

const firstSectionCategories = [
  "CHINESE VEGETABLES",
  "BEANSPROUT",
  "HERBS AND SPICES",
  "TOFU",
  "MUSHROOM",
  "MICROHERB",
  "SALADS",
];

export const PickingSlipPDF = ({ order, language = "single" }) => {
  if (order?.id === undefined) return null;

  const firstSection = [];
  const secondSection = [];
  const thirdSection = [];

  const lineItems = order.lineItems;
  lineItems.forEach((item) => {
    if (isPrepacked(item.productVariant.name.toUpperCase())) {
      thirdSection.push(item);
    } else {
      if (firstSectionCategories.includes(item.product.category.name_EN.toUpperCase())) {
        firstSection.push(item);
      } else {
        secondSection.push(item);
      }
    }
  });
  firstSection.sort((a, b) => {
    return a.productVariant.name.toUpperCase() > b.productVariant.name.toUpperCase() ? 1 : -1;
  });
  secondSection.sort((a, b) => {
    return a.productVariant.name.toUpperCase() > b.productVariant.name.toUpperCase() ? 1 : -1;
  });

  const tableColumns = [
    {
      title: "#",
      dataKey: "index",
      key: "index",
      span: 1,
      align: "left",
      render: (_, record, index) => {
        return record && `${index + 1}`;
      },
    },
    {
      title: "Description",
      dataKey: "name",
      key: "name",
      span: 8,
      align: "left",
      render: (_, record) => {
        let name = record.name;
        let specialNotes = "";
        if (!record.isOpenItem) {
          name = language === "single" ? record.product.name_EN : record.product.name;
        }

        // specialNotes for na and replace items
        if (record.pickingStatus === "na") specialNotes = "(NOT available)";
        if (record.pickingStatus === "replace") specialNotes = "(REPLACE)";

        return name + " " + specialNotes;
      },
    },
    {
      title: "Comments",
      dataKey: "notes",
      key: "notes",
      span: 6,
      align: "left",
    },
    {
      title: "Unit",
      dataKey: "packagingUnit",
      key: "packagingUnit",
      span: 5,
      align: "left",
      render: (_, record) => {
        if (record.isOpenItem) return record.packagingUnit ?? "";
        return language === "single" ? record.productVariant.name_EN : record.productVariant.name;
      },
    },

    {
      title: "Quantity",
      dataKey: "quantity",
      key: "quantity",
      span: 4,
      align: "left",
    },
  ];

  return (
    <Document>
      <Page
        size="A4"
        style={{
          paddingTop: 32,
          paddingBottom: 48,
          paddingHorizontal: 32,
          backgroundColor: "#fff",
          fontFamily: "Microsoft YaHei",
        }}
      >
        <Row>
          <Stack
            sx={{
              width: "50%",
              alignItems: "flex-start",
            }}
          >
            <Typography type="header" sx={{ fontSize: "24px", fontFamily: "Microsoft YaHei" }}>
              {order?.customer?.name}
            </Typography>
            <Typography sx={{ fontSize: "16px" }}>#{order?.name}</Typography>
            <Typography sx={{ fontSize: "16px" }}>Total: {order.lineItems.length} items</Typography>
          </Stack>
          <Stack
            sx={{
              width: "50%",
              alignItems: "flex-end",
            }}
          >
            <Typography type="header" sx={{ fontSize: "24px", textAlign: "right" }}>
              Picking Slip
            </Typography>
            <Typography sx={{ textAlign: "right", fontSize: "16px" }}>
              Date: {moment(order.orderDate).format(DATE_FORMAT)}
            </Typography>
            <Typography sx={{ textAlign: "right", fontSize: "12px", color: "#555555" }}>
              Printed at: {moment().format("YYYY-MM-DD:HH:MM:SS")}
            </Typography>
          </Stack>
        </Row>

        <View
          style={{
            padding: "6px",
            border: "1px solid black",
            borderRadius: "4px",
            margin: "12px 0",
          }}
        >
          <Typography type="header" sx={{ textAlign: "left", fontSize: "14px", color: "#333333" }}>
            Notes:
          </Typography>
          <Typography type="header" sx={{ textAlign: "left" }}>
            {order.packerNotes}
          </Typography>
        </View>

        {/* 1st section */}
        <Typography type="header" sx={{ textAlign: "left" }}>
          Section 1 ({firstSection.length} items)
        </Typography>

        {/* 1st table header  */}
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "nowrap",
            columnGap: "4px",
            padding: "8px",
          }}
        >
          {tableColumns.map((th) => {
            const columnWidth = `${(th.span / 24) * 100}%`;
            return (
              <Typography
                key={th.key}
                sx={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  width: columnWidth,
                  textAlign: th.align,
                  margin: "0 4px",
                }}
              >
                {th.title}
              </Typography>
            );
          })}
        </View>
        <HorizontalLine />

        {/* 1st table body  */}

        {firstSection &&
          firstSection.map((item, index) => {
            return (
              <View
                key={item.id}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "nowrap",
                  columnGap: "4px",
                  padding: "8px",
                  borderBottom: "0.35px solid lightgrey",
                }}
              >
                {tableColumns.map((th) => {
                  const columnWidth = `${(th.span / 24) * 100}%`;
                  const value = item[th.dataKey];
                  const strike =
                    (th.dataKey === "unitPrice" || th.dataKey === "lineTotal") &&
                    item.pickingStatus === "na";
                  const textValue = th.render ? th.render(value, item, index) : value;
                  return (
                    <Typography
                      key={th.key}
                      sx={{
                        fontSize: "12px",
                        width: columnWidth,
                        textAlign: th.align,
                        fontFamily: "Microsoft YaHei",
                        textDectoration: strike ? "line-through" : "none",
                        margin: "0 4px",
                      }}
                    >
                      {typeof textValue === "string" ? (
                        textValue
                      ) : (
                        <>
                          {textValue[0]}
                          <Typography
                            key={th.key + "_notes"}
                            sx={{
                              width: columnWidth,
                              margin: "0 4px",
                              textAlign: th.align,
                              fontFamily: "Microsoft YaHei",
                              textDectoration: strike ? "line-through" : "none",
                              color: "grey",
                            }}
                          >
                            {textValue[1]}
                          </Typography>
                        </>
                      )}
                    </Typography>
                  );
                })}
              </View>
            );
          })}

        <HorizontalLine sx={{ margin: "24px 0 16px", borderStyle: "dashed" }} />

        {/* 2nd table  */}

        <Typography type="header" sx={{ textAlign: "left", marginTop: "20px" }}>
          Section 2 ({secondSection.length} items)
        </Typography>

        {/* 2nd table header  */}
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "nowrap",
            columnGap: "4px",
            padding: "8px",
          }}
        >
          {tableColumns.map((th) => {
            const columnWidth = `${(th.span / 24) * 100}%`;
            return (
              <Typography
                key={th.key}
                sx={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  width: columnWidth,
                  textAlign: th.align,
                  margin: "0 4px",
                }}
              >
                {th.title}
              </Typography>
            );
          })}
        </View>
        <HorizontalLine />

        {/*2nd table body  */}
        {secondSection &&
          secondSection.map((item, index) => {
            return (
              <View
                key={item.id}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "nowrap",
                  columnGap: "4px",
                  padding: "8px",
                  borderBottom: "0.35px solid lightgrey",
                }}
              >
                {tableColumns.map((th) => {
                  const columnWidth = `${(th.span / 24) * 100}%`;
                  const value = item[th.dataKey];
                  const strike =
                    (th.dataKey === "unitPrice" || th.dataKey === "lineTotal") &&
                    item.pickingStatus === "na";
                  const textValue = th.render ? th.render(value, item, index) : value;
                  return (
                    <Typography
                      key={th.key}
                      sx={{
                        fontSize: "12px",
                        width: columnWidth,
                        textAlign: th.align,
                        fontFamily: "Microsoft YaHei",
                        textDectoration: strike ? "line-through" : "none",
                        margin: "0 4px",
                      }}
                    >
                      {typeof textValue === "string" ? (
                        textValue
                      ) : (
                        <>
                          {textValue[0]}
                          <Typography
                            key={th.key + "_notes"}
                            sx={{
                              width: columnWidth,
                              margin: "0 4px",
                              textAlign: th.align,
                              fontFamily: "Microsoft YaHei",
                              textDectoration: strike ? "line-through" : "none",
                              color: "grey",
                            }}
                          >
                            {textValue[1]}
                          </Typography>
                        </>
                      )}
                    </Typography>
                  );
                })}
              </View>
            );
          })}

        {/* 3rd table  */}

        <Typography type="header" sx={{ textAlign: "left", marginTop: "20px" }}>
          Section 3 ({thirdSection.length} items)
        </Typography>

        {/* 3rd table header  */}
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "nowrap",
            columnGap: "4px",
            padding: "8px",
          }}
        >
          {tableColumns.map((th) => {
            const columnWidth = `${(th.span / 24) * 100}%`;
            return (
              <Typography
                key={th.key}
                sx={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  width: columnWidth,
                  textAlign: th.align,
                  margin: "0 4px",
                }}
              >
                {th.title}
              </Typography>
            );
          })}
        </View>
        <HorizontalLine />

        {/*3rd table body  */}
        {thirdSection &&
          thirdSection.map((item, index) => {
            return (
              <View
                key={item.id}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "nowrap",
                  columnGap: "4px",
                  padding: "8px",
                  borderBottom: "0.35px solid lightgrey",
                }}
              >
                {tableColumns.map((th) => {
                  const columnWidth = `${(th.span / 24) * 100}%`;
                  const value = item[th.dataKey];
                  const strike =
                    (th.dataKey === "unitPrice" || th.dataKey === "lineTotal") &&
                    item.pickingStatus === "na";
                  const textValue = th.render ? th.render(value, item, index) : value;
                  return (
                    <Typography
                      key={th.key}
                      sx={{
                        fontSize: "12px",
                        width: columnWidth,
                        textAlign: th.align,
                        fontFamily: "Microsoft YaHei",
                        textDectoration: strike ? "line-through" : "none",
                        margin: "0 4px",
                      }}
                    >
                      {typeof textValue === "string" ? (
                        textValue
                      ) : (
                        <>
                          {textValue[0]}
                          <Typography
                            key={th.key + "_notes"}
                            sx={{
                              width: columnWidth,
                              margin: "0 4px",
                              textAlign: th.align,
                              fontFamily: "Microsoft YaHei",
                              textDectoration: strike ? "line-through" : "none",
                              color: "grey",
                            }}
                          >
                            {textValue[1]}
                          </Typography>
                        </>
                      )}
                    </Typography>
                  );
                })}
              </View>
            );
          })}

        <Typography sx={{ margin: "30px auto 0", fontSize: "12px" }}>
          -End of Picking Slip #{order?.name} -{order?.customer?.name}-
        </Typography>

        <PageNumber />
      </Page>
    </Document>
  );
};
