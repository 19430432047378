import { gql } from "@apollo/client";

export const FranchisesQuery = gql`
  query FranchisesQuery($take: Int, $skip: Int, $queryString: String) {
    customers(
      orderBy: { name: asc }
      take: $take
      skip: $skip
      where: { name: { contains: $queryString, mode: insensitive }, isFranchise: { equals: true } }
    ) {
      id
      name
      customerCode
      deliveryAddress
      accountBalance
      ordersCount
      orders(take: 1, orderBy: { orderDate: desc }) {
        id
        orderDate
      }
      franchise {
        name
      }
      isFranchise
      branchesCount
      salesperson {
        name
      }
    }
    customersCount(
      where: { name: { contains: $queryString, mode: insensitive }, isFranchise: { equals: true } }
    )
  }
`;

export const CustomersQuery = gql`
  query CustomersQuery($filters: CustomerWhereInput!) {
    customers(orderBy: { name: asc }, take: 50, where: $filters) {
      id
      name
      customerCode
      deliveryAddress
      accountBalance
      ordersCount
      invoiceTemplate
      orders(take: 1, orderBy: { orderDate: desc }) {
        id
        orderDate
      }
      franchise {
        name
      }
      isFranchise
      branchesCount
      salesperson {
        name
      }
    }
  }
`;

export const AllCustomersQuery = gql`
  query AllCustomersQuery($take: Int, $skip: Int, $queryString: String) {
    customers(
      orderBy: { name: asc }
      take: $take
      skip: $skip
      where: { name: { contains: $queryString, mode: insensitive }, isFranchise: { equals: false } }
    ) {
      id
      name
      customerCode
      deliveryAddress
      accountBalance
      ordersCount
      orders(take: 1, orderBy: { orderDate: desc }) {
        id
        orderDate
      }
      franchise {
        name
      }
      isFranchise
      branchesCount
      salesperson {
        name
      }
    }
    customersCount(
      where: { name: { contains: $queryString, mode: insensitive }, isFranchise: { equals: false } }
    )
  }
`;

export const SortedCustomersQuery = gql`
  query sortedCustomersQuery(
    $filters: CustomerWhereInput!
    $sortType: String
    $take: Int
    $skip: Int
  ) {
    sortedCustomers(where: $filters, sortType: $sortType, take: $take, skip: $skip) {
      id
      name
      customerCode
      deliveryAddress
      accountBalance
      ordersCount
      invoiceTemplate
      organisation {
        id
        name
      }
      orders(take: 1, orderBy: { orderDate: desc }) {
        id
        name
        orderDate
        paymentStatus
      }
      franchise {
        name
      }
      isFranchise
      branchesCount
      salesperson {
        name
      }
    }
    customersCount(where: $filters)
  }
`;

export const CustomersSendStatementsQuery = gql`
  query CustomersSendStatementsQuery(
    $filters: CustomerWhereInput!
    $sortType: String
    $take: Int
    $skip: Int
  ) {
    sortedCustomers(take: $take, skip: $skip, sortType: $sortType, where: $filters) {
      id
      customerCode
      name
      phone
      email
      deliveryAddress
      billingAddress
      accountBalance
      ordersCount
      paymentTerms
      paymentMethod
      statementMethod
      invoiceTemplate
      orders(
        where: {
          paymentStatus: { not: { equals: "paid" } }
          status: { equals: "confirmed" }
          # isCreditNote: { equals: false }
        }
        orderBy: { orderDate: asc }
      ) {
        id
        name
        orderDate
        paymentStatus
        balanceDue
        balancePaid
        total
        lineItemsCount
        status
        isCreditNote
      }
      paymentBatches(orderBy: { receivedDate: desc }) {
        id
        sequence
        name
        amount
        receivedDate
        paymentType
      }
      franchise {
        name
      }
      salesperson {
        name
      }
    }
    customersCount(where: $filters)
  }
`;

export const CustomerStatementsQuery = gql`
  query CustomerStatementsQuery($id: ID!) {
    customer(where: { id: $id }) {
      id
      customerCode
      name
      phone
      email
      deliveryAddress
      billingAddress
      accountBalance
      ordersCount
      paymentTerms
      paymentMethod
      statementMethod
      isFranchise
      invoiceTemplate
      branches {
        name
        orders(
          where: { paymentStatus: { not: { equals: "paid" } }, status: { equals: "confirmed" } }
          orderBy: { orderDate: asc }
        ) {
          id
          name
          orderDate
          paymentStatus
          balanceDue
          balancePaid
          total
          lineItemsCount
          status
          isCreditNote
        }
      }
      orders(
        where: { paymentStatus: { not: { equals: "paid" } }, status: { equals: "confirmed" } }
        orderBy: { orderDate: asc }
      ) {
        id
        name
        orderDate
        paymentStatus
        balanceDue
        balancePaid
        total
        lineItemsCount
        status
        isCreditNote
      }
    }
  }
`;

export const CustomersForAccountBalanceQuery = gql`
  query CustomersForAccountBalanceQuery {
    customers(
      orderBy: { name: asc }
      where: {
        isFranchise: { equals: false }
        OR: [{ accountBalance: { lt: "0" } }, { accountBalance: { gt: "0" } }]
      }
    ) {
      id
      customerCode
      name
      phone
      email
      deliveryAddress
      billingAddress
      accountBalance
      ordersCount
      paymentTerms
      paymentMethod
      statementMethod
      invoiceTemplate
      orders(
        where: { paymentStatus: { not: { equals: "paid" } }, status: { equals: "confirmed" } }
        orderBy: { orderDate: asc }
      ) {
        id
        name
        orderDate
        paymentStatus
        balanceDue
        balancePaid
        total
        lineItemsCount
        status
        isCreditNote
      }
      paymentBatches(orderBy: { receivedDate: desc }) {
        id
        sequence
        name
        amount
        receivedDate
        paymentType
      }
      franchise {
        name
      }
      salesperson {
        name
      }
    }
  }
`;

export const UpdateCustomerAccountBalanceMutation = gql`
  mutation UpdateCusstomerAccountBalanceMutation($id: ID!, $accountBalance: Decimal!) {
    updateCustomer(where: { id: $id }, data: { accountBalance: $accountBalance }) {
      id
      accountBalance
    }
  }
`;

export const CustomerAccountBalanceHealthQuery = gql`
  query CustomerAccountBalanceHealthQuery($id: ID!) {
    customer(where: { id: $id }) {
      orders(where: { status: { equals: "confirmed" } }, orderBy: { orderDate: desc }) {
        id
        name
        total
      }
      paymentBatches(orderBy: { receivedDate: desc }) {
        id
        name
        amount
        receivedDate
      }
    }
  }
`;

export const CustomersForOrderQuery = gql`
  query CustomersForOrderQuery {
    customers(orderBy: { name: asc }, where: { isFranchise: { equals: false } }) {
      id
      name
      isFrequentCustomer
      deliveryAddress
      accountBalance
      franchise {
        name
      }
      salesperson {
        name
      }
    }
  }
`;

export const CustomersSelectedQuery = gql`
  query CustomersSelectedQuery {
    customers {
      id
      name
      phone
      email
      deliveryAddress
    }
  }
`;

export const SingleCustomerQuery = gql`
  query SingleCustomerQuery($id: ID!, $take: Int, $skip: Int) {
    customer(where: { id: $id }) {
      id
      name
      email
      customerCode
      phone
      billingAddress
      deliveryAddress
      notes
      accountBalance
      paymentTerms
      paymentMethod
      invoiceTemplate
      firstName
      lastName
      fixedMargin
      isFranchise
      franchise {
        name
      }
      orders(take: $take, skip: $skip, orderBy: { orderDate: desc }) {
        id
        name
        total
        balancePaid
        balanceDue
        orderDate
        paymentStatus
        status
        isCreditNote
        payments {
          id
          name
          amount
          receivedDate
          batch {
            id
            name
          }
        }
      }
      ordersCount
      paymentBatches(take: 50, orderBy: { receivedDate: desc }) {
        id
        name
        amount
        receivedDate
        payments {
          order {
            id
            name
          }
        }
      }
    }
  }
`;

export const SingleCustomerForOrderQuery = gql`
  query SingleCustomerForOrderQuery($id: ID!) {
    customer(where: { id: $id }) {
      id
      name
      email
      phone
      billingAddress
      deliveryAddress
      notes
      accountBalance
      paymentTerms
      paymentMethod
      firstName
      lastName
      invoiceTemplate
      fixedMargin
      franchise {
        id
        name
      }
    }
  }
`;

export const CustomerOrderHistoryQuery = gql`
  query CustomerOrderHistoryQuery($id: ID!) {
    customer(where: { id: $id }) {
      id
      name
      email
      phone
      billingAddress
      deliveryAddress
      paymentTerms
      paymentMethod
      firstName
      lastName
      orders(take: 5, orderBy: { createdAt: desc }) {
        id
        name
        orderDate
        sequence
        total
        lineItemsCount
      }
    }
  }
`;

export const updateCustomerEmailMutation = gql`
  mutation updateCustomerEmailMutation($id: ID!, $email: String) {
    updateCustomer(where: { id: $id }, data: { email: $email }) {
      email
    }
  }
`;

export const CustomersForLogisticSetupQuery = gql`
  query CustomersForLogisticSetupQuery($filters: CustomerWhereInput!) {
    customers(orderBy: { name: asc }, where: $filters) {
      id
      name
      customerCode
      deliveryAddress
      accountBalance
      ordersCount
      franchise {
        name
      }
      defaultRunName
      isFranchise
    }
  }
`;

export const BulkUpdateCustomersRunsMutation = gql`
  mutation bulkUpdateCustomersRunsMutation($data: [CustomerUpdateArgs!]!) {
    updateCustomers(data: $data) {
      id
      name
      defaultRunName
    }
  }
`;

export const SingleCustomerContextForOrderQuery = gql`
  query SingleCustomerContextForOrderQuery($id: ID!, $filters: QuotationWhereInput) {
    customer(where: { id: $id }) {
      id
      name
      email
      phone
      billingAddress
      deliveryAddress
      notes
      accountBalance
      paymentTerms
      paymentMethod
      firstName
      lastName
      fixedMargin
      franchise {
        id
        name
      }
      quotations(where: $filters, orderBy: { end: asc }) {
        id
        name
        start
        end
        status
        lineItems {
          id
          name
          unitPrice
          productVariant {
            id
            name
          }
        }
      }
    }
  }
`;
